//@flow

import React from 'react';

import SEO from '../../components/seo';
import { PrivateRoute } from '../../services/auth';
import { getUrlVars } from '../../utils';

import QuizzScreen from '../../screens/Quizz';

const QuizzPage = ({ location }: any) => {
  const chapterNumber = parseInt(getUrlVars(location.href)['chapter'], 10) || 1;

  return (
    <>
      <SEO title="Quizz" />
      <QuizzScreen
        chapterNumber={chapterNumber}
      />
    </>
  );
};

export default PrivateRoute(QuizzPage);
