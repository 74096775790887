import styled from 'styled-components';

export const Inner = styled.div`
  position: relative;
  display: flex;
  border-radius: 6px;
  padding: 15px;
  background-image: linear-gradient(to top right, #293352, #121624);
  font-size: 16px;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top right, #191C24, #56678f);
    opacity: 0;
    z-index: 0;
    border-radius: 6px;

    backface-visibility: hidden;
    transform: translate3d(0,0,0);

    transition: opacity 300ms;
  }
`;

export const Prefix = styled.div`
  margin-right: 8px;
  font-weight: bold;
  z-index: 1;
`;

export const Label = styled.div`
  flex: 1;
  z-index: 1;
  font-size: 14px;
  padding-right: 10px;
`;

export const Status = styled.div`
  flex-basis: 20px;
  z-index: 1;

  svg {
    &.green {
      * {
        fill: #67F5CB;
      }
    }

    &.red {
      * {
        fill: #EE1919;
      }
    }
  }
`;


export const Wrapper = styled.div`
  border-radius: 6px;
  background-image: linear-gradient(to bottom, #5E81E0, #8D74CF);
  padding: 1px;
  margin: 15px 0;
  overflow: hidden;
  cursor: pointer;

  &.checked {
    ${Inner} {
      &:before {
        opacity: 1;
      }
    }
  }
`;