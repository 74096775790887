import styled from 'styled-components';

export const Wrapper = styled.main`
  h2 {
    margin: 20px 0;
  }
  
  .question {
    font-size: 18px;
    max-width: 80%;
    margin: 20px 0 5px;
  }

  .instructions {
    font-size: 12px;
  }

  .options {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px 20px; */
  }

  .actions {
    text-align: right;
    margin-top: 30px;

    button {
      display: inline-block;
      margin: 0;
    }
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;

  .label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    padding-right: 15px;
  }

  .progress {
    flex: 1;

    .gauge-bg {
      background-color: #5162a5;
      width: 100%;
      height: 6px;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
    }

    .slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 3px;
      background: linear-gradient(to right, #5071CA, #B194FF);
      transition: width 500ms ease-in-out;
    }
  }
`;

export const ResultWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 50vh;
  flex-direction: column;

  .text {
    h4 {
      font-size: 20px;
      margin: 30px 0 5px;
    }

    p {
      margin: 0 0;
      font-size: 16px;
    }
  }

  .action-wrapper {
    display: block;
    text-align: right;
    width: 100%;

    .button {
      display: inline-block;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
`;