import styled from 'styled-components';

export const Wrapper = styled.main`
  .question {
    font-size: 18px;
    max-width: 90%;
    margin: 20px 0 5px;
  }

  .instructions {
    font-size: 12px;
  }

  .options {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px 20px; */
  }

  .actions {
    text-align: right;
    margin-top: 30px;

    button {
      display: inline-block;
      margin: 0;
    }
  }
`;
