import React, { useState } from 'react';

import Item from './Item';

import {
  Wrapper,
} from './Question.style';
import { FormattedMessage } from 'gatsby-plugin-intl';

type Props = {
  quizz: any,
  singleChoice: Boolean,
  hideHints: Boolean,
  onValidate: (success: Boolean, percentage: Number) => void,
};

const Question = ({ quizz, onValidate, singleChoice, hideHints }: Props) => {
  const [displayResult, setDisplayResult] = useState(false);
  const [selected, setSelected] = useState([]);

  const itemClicked = (id) => {
    const newSelected = singleChoice ? [] : selected.slice();
    const pos = newSelected.indexOf(id);

    if (pos === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(pos, 1);
    }

    if (displayResult) {
      setDisplayResult(false);
    }

    setSelected(newSelected);
  }

  const validateAnswers = () => {
    let success = true;
    let errors = 0;

    quizz.options.forEach(o => {
      const isSelected = selected.indexOf(o.id) !== -1;
      
      if (o.good !== isSelected) {
        success = false;
        errors++;
      }
    });
    const rate = 1 - (errors / quizz.options.length);

    if (displayResult || success || hideHints) {
      onValidate(success, rate, selected);
    } else {
      setDisplayResult(true);
    }
  }

  return ( 
    <Wrapper>
      <h3 className="question">{quizz.title}</h3>
      {!singleChoice ? (
        <div className="instructions"><FormattedMessage id="quizz.instructions" /></div>
      ) : null}
      <div className="options">
        {quizz.options.map((q, idx) => {
          return (
            <Item
              key={q.id}
              id={q.id}
              good={q.good}
              prefix={`${String.fromCharCode(97 + idx)}.`}
              label={q.name}
              displayResult={displayResult}
              checked={selected.indexOf(q.id) !== -1}
              onClick={itemClicked}
            />
          );
        })}
      </div>
      
      <div className="actions">
        <button
          className="button"
          onClick={validateAnswers}
          disabled={hideHints && !selected.length}
        >
          {displayResult && !hideHints ?
            <FormattedMessage id="quizz.next" /> :
            <FormattedMessage id="quizz.validate" />
          }
        </button>
      </div>
    </Wrapper>
  );
};

Question.defaultProps = {
  singleChoice: false,
  hideHints: false,
};

export default Question;
