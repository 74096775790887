//@flow

import React from 'react';
import classNames from 'classnames';


import CheckIcon from '../../icons/QuizzCheck.svg';
import WrongIcon from '../../icons/Wrong.svg';

import {
  Wrapper,
  Inner,
  Prefix,
  Label,
  Status,
} from './Item.style';

type Props = {
  id: any,
  displayResult: Boolean,
  good: Boolean,
  checked: Boolean,
  label: String,
  prefix: String,
  onClick: () => void
};

const renderStatus = (good, checked, displayResult) => {
  if (!displayResult && checked) {
    return <CheckIcon />;
  } else if (!displayResult) {
    return null;
  }

  if (good && (checked || displayResult)) {
    return <CheckIcon className="green" />;
  }

  if (!good && checked) {
    return <WrongIcon className="red" />;
  }
}

const Item = ({ id, prefix, good, displayResult, label, checked, onClick }: Props) => {
  return (
    <Wrapper
      className={classNames({ checked })}
      onClick={() => !displayResult && onClick(id)}
    >
      <Inner>
        <Prefix>{prefix}</Prefix>
        <Label>{label}</Label>
        <Status>
          {renderStatus(good, checked, displayResult)}
        </Status>
      </Inner>
    </Wrapper>
  );
};

Item.defaultProps = {
  checked: false,
  small: false,
};

export default Item;
